/*eslint-disable*/
import gsap from "gsap";

class BusinessChatStory {
  constructor(parent, story) {
    this.parent = parent;
    this.story = story;
    this.offset = 295;

    const windowWidth = window.innerWidth;
    if (document.querySelector(`${this.story}`)) {
      if (windowWidth >= 300 && windowWidth <= 992) {
        if (window.innerHeight >= 300) {
          this.init();
        }
      } else {
        if (windowWidth >= 300) {
          this.init();
        }
      }
    }
  }

  init() {
    //Initialize the Story Animations
    const ChatController = new ScrollMagic.Controller();

    //Story 1
    const chatMessages = document.querySelectorAll(`${this.story} li`);
    const chatHeadline = document.querySelectorAll(
      `${this.story} .story-info__line`
    );

    //simple stagger
    const ChatTimeline = new gsap.timeline({
      defaults: { ease: "power1.out" }
    });

    const chatOverlay = document.querySelector(`${this.story} .black-gradient`);

    ChatTimeline.fromTo(
      chatOverlay,
      0.2,
      {
        autoAlpha: 0
      },
      {
        autoAlpha: 0.5
      }
    )
      .fromTo(
        chatMessages,
        {
          x: "-20",
          // width: 0
          autoAlpha: 0
        },
        {
          x: "0",
          // width: "355px",
          autoAlpha: 1,
          duration: 0.5,
          stagger: 0.4 //space each element's animation out by 0.5 seconds
        }
      )
      .fromTo(
        chatHeadline,
        0.3,
        {
          width: 0
        },
        {
          width: "50px",
          duration: 0.6
        }
      );

    this.setOffset();
    ChatTimeline.pause();

    const self = this;

    window.addEventListener("DOMContentLoaded", self.setOffset, true);
    window.addEventListener("resize", self.setOffset, true);

    let Story1Scene = new ScrollMagic.Scene({
      triggerElement: `${this.parent} .story-wrapper`,
      duration: "50%",
      pushFollowers: false,
      offset: this.offset
    })
      .on("start", function() {
        ChatTimeline.play();
        // console.log("did the animation play");
      })
      // .setPin(`${this.parent} .story-wrapper`)
      // .setTween(ChatTimeline)
      .addTo(ChatController);
  }

  setOffset() {
    let windowWidth = window.innerWidth;

    if (windowWidth >= 1200) {
      this.offset = 335;
    } else if (windowWidth >= 300 && windowWidth < 410) {
      this.offset = 250;
    } else if (windowWidth >= 410 && windowWidth < 525) {
      this.offset = 190;
    } else if (windowWidth >= 525 && windowWidth < 767) {
      this.offset = 400;
    } else if (windowWidth >= 767 && windowWidth < 1200) {
      this.offset = 600;
    }
  }
}

export default BusinessChatStory;
