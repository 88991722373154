/*eslint-disable*/
import gsap from "gsap";

class AdaptStory {
  constructor() {
    this.offset = 295;

    const windowWidth = window.innerWidth;

    if(document.querySelector('.js-story2')){
      if (windowWidth >= 300 && windowWidth <= 992) {
        if (window.innerHeight >= 300) {
          this.init();
        }
      } else {
        if (windowWidth >= 300) {
          this.init();
        }
      }
    }
  }

  init() {
    //Initialize the Story Animations
    let AdaptController = null;
    AdaptController = new ScrollMagic.Controller();

    //Story 1
    const AdaptPart1Timeline = new gsap.timeline({
      defaults: { ease: "power1.out", duration: 1 }
    });
    const AdaptPart2Timeline = new gsap.timeline({
      defaults: { ease: "power1.out" }
    });

    const storyPart1 = document.querySelector(".js-story2 .js-part1");
    const storyPart2 = document.querySelector(".js-story2 .js-part2");

    const part1Mobile = storyPart1.querySelector(".js-part1-mobile");
    const part2Mobile = storyPart2.querySelector(".js-part2-mobile");

    AdaptPart1Timeline.fromTo(
      part1Mobile,
      0.2,
      {
        y: "50",
        autoAlpha: 0
      },
      {
        y: "-10",
        autoAlpha: 1
      }
    );

    AdaptPart2Timeline.fromTo(
      part2Mobile,
      0.2,
      {
        y: "50",
        autoAlpha: 0
      },
      {
        y: "-10",
        autoAlpha: 1
      }
    );

    this.setOffset();

    AdaptPart1Timeline.pause();
    AdaptPart2Timeline.pause();

    const self = this;

    let Story1Scene = new ScrollMagic.Scene({
      triggerElement: ".js-story2 .js-part1",
      duration: "80%",
      pushFollowers: true,
      offset: this.offset
    })
      .on("start", function() {
        AdaptPart1Timeline.play();
      })
      .addTo(AdaptController);

    let Story2Scene = new ScrollMagic.Scene({
      triggerElement: ".js-story2 .js-part2",
      duration: "80%",
      pushFollowers: true,
      offset: this.offset
    })
      .on("start", function() {
        AdaptPart2Timeline.play();
      })
      .addTo(AdaptController);

    window.addEventListener("DOMContentLoaded", self.setOffset, true);
    window.addEventListener("resize", self.setOffset, true);
  }

  setOffset() {
    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;

    if (windowWidth >= 1200) {
      this.offset = 320;
    } else if (windowWidth >= 370 && windowWidth < 767) {
      this.offset = 200;
    } else if (windowWidth >= 410 && windowWidth < 767) {
      this.offset = 250;
    } else if (windowWidth >= 767 && windowWidth < 1024) {
      this.offset = 350;
    } else if (windowWidth >= 1024 && windowWidth < 1200) {
      this.offset = 280;
    }
  }
}

export default AdaptStory;
