/*eslint-disable*/
import gsap from "gsap";

class ChatStory {
  constructor(parent, story) {
    this.parent = parent;
    this.story = story;
    this.offset = 295;

    const windowWidth = window.innerWidth;
    if (document.querySelector(`${this.story}`)) {
      if (windowWidth >= 300 && windowWidth <= 992) {
        if (window.innerHeight >= 300) {
          this.init();
        }
      } else {
        if (windowWidth >= 300) {
          this.init();
        }
      }
    }
  }

  init() {
    //Initialize the Story Animations
    const ChatController = new ScrollMagic.Controller();

    //Story 1
    const chatMessages = document.querySelectorAll(`${this.story} li`);

    //simple stagger
    const ChatTimeline = new gsap.timeline({
      defaults: { ease: "power1.out" }
    });

    const chatOverlay = document.querySelector(`${this.story} .black-gradient`);

    ChatTimeline.fromTo(
      chatOverlay,
      0.2,
      {
        autoAlpha: 0
      },
      {
        autoAlpha: 0.5
      }
    ).fromTo(
      chatMessages,
      {
        y: "10",
        autoAlpha: 0
      },
      {
        y: "0",
        autoAlpha: 1,
        duration: 1,
        stagger: 0.5 //space each element's animation out by 0.5 seconds
      }
    );

    this.setOffset();
    ChatTimeline.pause();

    const self = this;

    window.addEventListener("DOMContentLoaded", self.setOffset, true);
    window.addEventListener("resize", self.setOffset, true);

    let Story1Scene = new ScrollMagic.Scene({
      triggerElement: `${this.parent} .story-wrapper`,
      duration: "50%",
      pushFollowers: false,
      offset: this.offset
    })
      .on("start", function() {
        ChatTimeline.play();
      })
      // .setPin(`${this.parent} .story-wrapper`)
      // .setTween(ChatTimeline)
      .addTo(ChatController);
  }

  setOffset() {
    let windowWidth = window.innerWidth;

    if (windowWidth >= 1200) {
      this.offset = 335;
    } else if (windowWidth >= 300 && windowWidth < 410) {
      this.offset = 280;
    } else if (windowWidth >= 410 && windowWidth < 525) {
      this.offset = 290;
    } else if (windowWidth >= 525 && windowWidth < 767) {
      this.offset = 400;
    } else if (windowWidth >= 767 && windowWidth < 1200) {
      this.offset = 350;
    }
  }
}

export default ChatStory;
