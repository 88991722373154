/**
 * Stylesheets
 */
import "../scss/main.scss";
import "../../node_modules/swiper/swiper.scss";

/**
 * Pug Templates
 * Comment these when you are making a production build
 */
import "../pug/index.pug";
import "../pug/index-jp.pug";
import "../pug/product.pug";
import "../pug/product-jp.pug";
import "../pug/supported-devices.pug";
import "../pug/device-detail.pug";
import "../pug/news.pug";
import "../pug/about-us.pug";
import "../pug/presskit.pug";
import "../pug/business.pug";
import "../pug/business-old.pug";
import "../pug/empty.pug";

/**
 * Polyfills
 */

import "@babel/polyfill";

/**
 * Scripts
 */

//Page components
import Header from "./components/interactions/header";
import Loader from "./components/interactions/loaderInteractions";
import Devicecard from "./components/interactions/supportDevice";
import ResponsiveImages from "./components/utilities/responsiveImages";

//Stories
import GoogleStory from "./components/stories/Google";
import AdaptStory from "./components/stories/Adapt";
import CheckStory from "./components/stories/Check";
import ChatStory from "./components/stories/Chat";
import BusinessChatStory from "./components/stories/BusinessChat";
import SupportDeivce from "./components/stories/SupportDevice";
import BusinessStory from "./components/stories/BusinessStory";

import See from "./components/sections/See";
import Mini from "./components/sections/Mini";
import CheckSection from "./components/sections/Check";
import Connect from "./components/sections/Connect";
import Devices from "./components/sections/Devices";

// using ES6 modules
import $ from "jquery";

import "bootstrap/js/dist/dropdown";
import ProductSwiper from "./components/productSwiper";
import DisplaySwiper from "./components/displaySwiper";
import LazyLoadWIthLozad from "./components/utilities/lazyLoad";

console.log("Live smart!"); // eslint-disable-line no-console

const loader = new Loader();

const devicecard = new Devicecard();

const header = new Header(
  ".ls-header",
  ".ls-mainnav",
  ".js-navtoggler",
  ".js-dropdown-menu",
  ".menu-list",
  ".menu-arrow"
);

const bannerImageResponsive = new ResponsiveImages(".banner-bgimg");

const story1ImageResponsive = new ResponsiveImages("#story1-img");
const story2ImageResponsive = new ResponsiveImages("#story2-img");
const story3ImageResponsive = new ResponsiveImages("#story3-img");
const story4ImageResponsive = new ResponsiveImages("#story4-img");
const story5ImageResponsive = new ResponsiveImages("#story5-img");
const story7ImageResponsive = new ResponsiveImages("#story7-img");
const story8ImageResponsive = new ResponsiveImages("#story8-img");
const story9ImageResponsive = new ResponsiveImages("#story9-img");

const story10ImageResponsive = new ResponsiveImages("#placement-img");
const story11ImageResponsive = new ResponsiveImages("#placement-img1");
const story12ImageResponsive = new ResponsiveImages("#setup");
const story13ImageResponsive = new ResponsiveImages("#outside-img");
const story14ImageResponsive = new ResponsiveImages("#knowmore");
const story15ImageResponsive = new ResponsiveImages("#business-img1");
const story16ImageResponsive = new ResponsiveImages("#business-img2");
const story17ImageResponsive = new ResponsiveImages("#business-img3");
const story18ImageResponsive = new ResponsiveImages("#business-img4");

// const supportDevices = new ResponsiveImages(".device-img");
// const supportDevice = new ResponsiveImage(".device-img");

const miniImageResponsive = new ResponsiveImages("#mini-img");
const businessImageResponsive = new ResponsiveImages("#business-img");

/**
 * Stories : START
 */
const Google = new GoogleStory();
const Adapt = new AdaptStory();
const Business = new BusinessStory();
const Check = new CheckStory();

// const Guest = new ChatStory(".js-guest", ".story--guest");
const GuestBusiness = new ChatStory(".js-guest", ".story--guest");
const Alert = new ChatStory(".js-alert", ".story--alert");

const chat1 = new BusinessChatStory(".js-chat-1", "#business-story1");
const chat2 = new BusinessChatStory(".js-chat-2", "#business-story2");
const chat3 = new BusinessChatStory(".js-chat-3", "#business-story3");
const chat4 = new BusinessChatStory(".js-chat-4", "#business-story4");
const chat5 = new BusinessChatStory(".js-chat-5", "#business-story5");
const chat6 = new BusinessChatStory(".js-chat-6", "#business-story6");
const chat7 = new BusinessChatStory(".js-chat-7", "#business-story7");
const chat8 = new BusinessChatStory(".js-chat-8", "#business-story8");
const chat9 = new BusinessChatStory(".js-chat-9", "#business-story9");

const supportDevice = new SupportDeivce(
  ".js-support-device",
  ".support-device__block"
);

//Sections
const SeeSection = new See();
const MiniSection = new Mini();
const DevicesSection = new Devices();
const ConnectSection = new Connect();
const WindowSection = new CheckSection();

/**
 * Images Lazy loading
 */
const StoryLazyLoad = new LazyLoadWIthLozad(".story__img");
const MiniProductImagesLazyLoad = new LazyLoadWIthLozad(".mini-product-img");
const StoryMobileImagesLazyLoad = new LazyLoadWIthLozad(".story__mobile-img");

/**
 * Product Swiper
 */
const productSwiper = new ProductSwiper();

/**
 * Display Swiper
 */
const displaySwiper = new DisplaySwiper();

$(".dropdown-toggle").dropdown();
