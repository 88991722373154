/*eslint-disable*/
class Header {
  constructor(header, menu, navtoggler, dropdownmenu, menulist, menuarrow) {
    this.header = document.querySelector(header);
    this.html = document.querySelector("html");
    this.toggler = document.querySelector(navtoggler);
    this.menu = document.querySelector(menu);
    this.dropdownmenu = document.querySelectorAll(dropdownmenu);
    this.menulist = document.querySelectorAll(menulist);
    this.menuarrow = document.querySelectorAll(menuarrow);
    this.init();
  }

  init() {
    this.addListener();
  }

  addListener() {
    const self = this;
    const headerScrollOffset = 100;
    let position = window.scrollY;
    let dropdownmenu = document.querySelectorAll(".js-dropdown-menu");

    this.toggler.addEventListener("click", function(e) {
      self.menu.classList.toggle("ls-show-menu");
      this.classList.toggle("is-active");
    });

    for (let i = 0; i < dropdownmenu.length; i++) {
      this.dropdownmenu[i].addEventListener("click", function(e) {
        self.menulist[i].classList.toggle("show");
        self.menuarrow[i].classList.toggle("active");
        this.classList.toggle("active");
      });
    }

    window.addEventListener(
      "scroll",
      () => {
        let scrollPos = window.scrollY;
        let screenResolution = window.innerWidth;

        if (window.scrollY >= headerScrollOffset) {
          self.header.classList.add("inverse");
        } else {
          self.header.classList.remove("inverse");
        }

        if (screenResolution >= 768) {
          if (position >= scrollPos) {
            self.header.classList.remove("nav-up");
            self.header.classList.add("nav-down");
          } else {
            self.header.classList.remove("nav-down");
            self.header.classList.add("nav-up");
          }
        }

        position = scrollPos;
      },
      false
    );
  }
}

export default Header;
