import lozad from 'lozad';

class LazyLoadWIthLozad {
    constructor(items) {
        this.items = document.querySelectorAll(items);
        this.init();
    }

    init(){
        this.items.forEach(item => {
            // passing a `NodeList` (e.g. `document.querySelectorAll()`) is also valid
            const itemObserver = lozad(item);
            itemObserver.observe();
        });
    }
}

export default LazyLoadWIthLozad;