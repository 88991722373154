import Swiper from 'swiper';

class ProductSwiper {
    constructor() {
        if(document.querySelector(".js-products-swiper")) {
            this.init();
        }
    }

    init() {
        const self = this;
        window.addEventListener("DOMContentLoaded", self.initProductSwiper, false);
        window.addEventListener("resize", self.initProductSwiper, false);
    }

    initProductSwiper() {
        if (window.innerWidth <= 992) {
          const productSwiper = new Swiper(".js-products-swiper", {
            // slidesPerView: 4,
            loop: false,
            // Responsive breakpoints
            breakpointsInverse: true,
            breakpoints: {
              320: {
                slidesPerView: 1.4,
                spaceBetween: 0,
                slidesOffsetBefore: 0,
                centeredSlides: true
              },
              370: {
                slidesPerView: 1.6,
                spaceBetween: 0,
                slidesOffsetBefore: 0,
                centeredSlides: true
              },
              // when window width is >= 600px
              600: {
                slidesPerView: 2.5,
                spaceBetween: 20,
                centeredSlides: true
              },
              // when window width is >= 320px
              992: {
                slidesPerView: 4,
                spaceBetween: 20,
                centeredSlides: false
              }
            }
          });
        }
        if (window.innerWidth > 992) {
          const productSwiperItem = document.querySelector(".js-products-swiper");
          if (!productSwiperItem.classList.contains("swiper-container-initialized")) {
            const slides = productSwiperItem.querySelectorAll(".swiper-slide");

            if (productSwiperItem.querySelector(".swiper-wrapper")) {
              productSwiperItem.querySelector(".swiper-wrapper").classList.add("row");
              productSwiperItem
                .querySelector(".swiper-wrapper")
                .classList.remove("swiper-wrapper");
            }

            slides.forEach(element => {
              element.classList.remove("swiper-slide");
              element.classList.remove("d-flex");
              element.classList.add("col-md-3");
            });
          }
        }
    }
}

export default ProductSwiper;