/*eslint-disable*/
import gsap from "gsap";

class GoogleStory {
  constructor() {
    const windowWidth = window.innerWidth;

    this.offset = 0;
    if(document.querySelector('.js-story1')){
      this.init();
    }
    // if(windowWidth >= 370  && windowWidth <= 992){
    //     console.log('lets animate');
    //     if(window.innerHeight >= 650) {
    //     }
    // }
    // else {
    //     if(windowWidth >= 370 ) {
    //         this.init();
    //     }
    // }
  }

  init() {
    //Initialize the Story Animations
    const StoryController = new ScrollMagic.Controller();

    //Story 1
    const Story1Timeline = new gsap.timeline({
      defaults: { ease: "power1.out" }
    });
    const Story2Timeline = new gsap.timeline({
      defaults: { ease: "power1.out" }
    });

    const story1Part1 = document.querySelector(".js-story1 .js-part1");
    const story1Part2 = document.querySelector(".js-story1 .js-part2");

    const story1ChatImage = story1Part1.querySelector(".story-chat__img");
    const story1ChatMsg = story1Part1.querySelector(".story-chat__msg");

    const story2ChatImage = story1Part2.querySelector(".story-chat__img");
    const story2ChatMsg = story1Part2.querySelector(".story-chat__msg");
    const story2DevicePulse = story1Part2.querySelector(".device-pulse");
    const story2DeviceLight = story1Part2.querySelector(".story-light");
    const story2DeviceStats = story1Part2.querySelectorAll(
      ".story-systemslist__system"
    );

    Story1Timeline.fromTo(
      story1ChatImage,
      0.2,
      {
        autoAlpha: 0
      },
      {
        autoAlpha: 1
      }
    ).fromTo(
      story1ChatMsg,
      0.4,
      {
        autoAlpha: 0,
        scale: 0.6
      },
      {
        autoAlpha: 1,
        scale: 1
      }
    );

    Story2Timeline.fromTo(
      story2DevicePulse,
      0.2,
      {
        autoAlpha: 0
      },
      {
        autoAlpha: 1
      },
      "pulse"
    )
      .fromTo(
        story2DeviceLight,
        0.2,
        {
          autoAlpha: 0
        },
        {
          autoAlpha: 1
        },
        "<-=1"
      )
      .fromTo(
        story2DeviceStats,
        0.4,
        {
          autoAlpha: 0
        },
        {
          autoAlpha: 1,
          stagger: 0.3
        },
        "<-=1"
      )
      .fromTo(
        story2ChatImage,
        0.6,
        {
          autoAlpha: 0
        },
        {
          autoAlpha: 1
        }
      )
      .fromTo(
        story2ChatMsg,
        0.6,
        {
          autoAlpha: 0,
          scale: 0.6
        },
        {
          autoAlpha: 1,
          scale: 1
        },
        "<-=1"
      );

    Story1Timeline.pause();
    Story2Timeline.pause();

    this.setOffset();

    const self = this;

    window.addEventListener("DOMContentLoaded", self.setOffset, true);
    window.addEventListener("resize", self.setOffset, true);

    let Story1Scene1 = new ScrollMagic.Scene({
      triggerElement: ".js-story1 .js-part1",
      duration: "60%",
      pushFollowers: false,
      offset: this.offset
    })
      .on("start", function() {
        Story1Timeline.play();
      })
      .addTo(StoryController);

    let Story1Scene2 = new ScrollMagic.Scene({
      triggerElement: ".js-story1 .js-part2",
      duration: "60%",
      pushFollowers: false,
      offset: this.offset
    })
      .on("start", function() {
        Story2Timeline.play();
      })
      .addTo(StoryController);
  }

  setOffset() {
    let windowWidth = window.innerWidth;

    if (windowWidth >= 767) {
      this.offset = 280;
    } else if (windowWidth >= 370 && windowWidth < 410) {
      this.offset = 200;
    } else if (windowWidth >= 410 && windowWidth < 767) {
      this.offset = 330;
    } else {
      this.offset = 295;
    }
  }
}

export default GoogleStory;
