import gsap from "gsap";

class Loader {
  constructor() {
    this.init();
  }

  init() {
    // document.querySelector('main').style.opacity = 0;

    const loader = document.querySelector(".ls-loader");
    const fabimg = document.querySelector(".fab-navigator__img");
    const fabdesc = document.querySelector(".fab-navigator__desc");
    const windowWidth = window.innerWidth;

    if (windowWidth >= 1200 && fabimg) {
      fabimg.addEventListener("mouseenter", function() {
        fabdesc.classList.toggle("active");
      });

      fabimg.addEventListener("mouseleave", function() {
        fabdesc.classList.toggle("active");
      });
    }

    if (loader) {
      this.animateLoadProgress();
    } else {
      window.addEventListener("load", () => {
        document.querySelector("body").classList.add("is-loaded");
        fabimg.classList.add("active");
        document.querySelector("main").style.opacity = 1;
      });
    }
  }

  animateLoadProgress() {
    const width = 100, // width of a progress bar in percentage
      perfData = window.performance.timing, // The PerformanceTiming interface
      EstimatedTime = -(perfData.loadEventEnd - perfData.navigationStart), // Calculated Estimated Time of Page Load which returns negative value.
      time = parseInt((EstimatedTime / 1000) % 60) * 100; // Converting EstimatedTime from miliseconds to seconds.
    window.addEventListener("DOMContentLoaded", function() {
      document.querySelector("main").style.opacity = 0;
    });

    var PercentageID = "#loadPercentage",
      start = 0,
      end = 100,
      durataion = time;
    this.animateValue(PercentageID, start, end, durataion);
  }

  // Percentage Increment Animation
  animateValue(id, start, end, duration) {
    var range = end - start,
      current = start,
      increment = end > start ? 1 : -1,
      stepTime = Math.abs(Math.floor(duration / range)),
      obj = document.querySelector(id);

    const self = this;
    const fabimg = document.querySelector(".fab-navigator__img");

    var timer = setInterval(function() {
      current += increment;
      obj.innerText = current;
      if (current == end) {
        clearInterval(timer);
        document.querySelector("body").classList.add("anim--effect-3");
        document.querySelector("body").classList.add("is-loaded");
        document.querySelector(".ls-loader").classList.add("is-loaded");
        if (fabimg) {
          setTimeout(() => {
            document
              .querySelector(".fab-navigator__img")
              .classList.add("active");
          }, 1000);
          setTimeout(() => {
            document
              .querySelector(".fab-navigator__desc")
              .classList.add("active");
          }, 1300);
          setTimeout(() => {
            document
              .querySelector(".fab-navigator__desc")
              .classList.remove("active");
          }, 6000);
        }
      }
    }, stepTime);

    document.querySelector("main").style.opacity = 1;
  }
}

export default Loader;
