import gsap from 'gsap';

class See {
    constructor(){
        if(document.querySelector('.section--see')){
            this.init();
        }
    }

    init(){
        //See Section
        const Controller = new ScrollMagic.Controller();

        const SeeTimeline = new gsap.timeline({defaults:{ease:"power1.out", duration:0.4}});

        const SeeHeading = document.querySelector('.section-heading--story');
        const SeeProductImg = document.querySelector('.see-product-img');

        SeeTimeline
            .fromTo(
                SeeProductImg,
                {
                    autoAlpha: 0,
                    x: "-200"
                },
                {
                    autoAlpha: 1,
                    x: "0"
                }
            )
            .fromTo(
                SeeHeading,
                {
                    autoAlpha: 0,
                },
                {
                    autoAlpha: 1,
                },
                "+=0.6"
            );

            SeeTimeline.pause();

        const StaticController = new ScrollMagic.Controller();

        const SeeScene = new ScrollMagic.Scene({
            triggerElement: '.section--see',
            duration: '60%',
            // offset: 100,
        })
        .on('enter', function(e){
            SeeTimeline.play();
        })
        .addTo(Controller);
    }
}

export default See;