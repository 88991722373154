import gsap from 'gsap';

class Connect {
    constructor(){
        if(document.querySelector('.js-window')){
            this.init();
        }
    }

    init(){
        //Window Section
        const Controller = new ScrollMagic.Controller();

        const CheckTimeline = new gsap.timeline({defaults:{ease:"power1.out", duration:0.2}});

        const storyPart1 = document.querySelector('.js-window');

        const mobile = storyPart1.querySelector('.js-window .story__mobile-img');

        CheckTimeline
            .fromTo(
                mobile,
                0.2,
                {
                    y: '50',
                    autoAlpha: 0,
                },
                {
                    y: '-10',
                    autoAlpha: 1,
                },
            );


        CheckTimeline.pause();

        const WindowScene = new ScrollMagic.Scene({
            triggerElement: '.js-window',
            duration: '60%',
            offset: 150,
        })
        .on('enter', function(e){
            CheckTimeline.play();
        })
        .addTo(Controller);
    }
}

export default Connect;