import gsap from 'gsap';

class Devices {
    constructor(){
        if(document.querySelector('.section--devices')){
            this.init();
        }
    }

    init(){
        //Mini Section

        const Controller = new ScrollMagic.Controller();

        const DevicesTimeline = new gsap.timeline({defaults:{ease:"power1.out", duration:1}});

        const DevicesContent = document.querySelector('.section--devices .section-heading');

        const DevicesTitles = document.querySelectorAll('.section--devices .devices-title');
        const DevicesItems = document.querySelectorAll('.section--devices .devices-list__item');

        DevicesTimeline
            .fromTo(
                DevicesContent, 0.4,
                {
                    y: '-50',
                    autoAlpha: 0,
                },
                {
                    y: '0',
                    autoAlpha: 1,
                }
            )
            .fromTo(
                DevicesTitles,
                {
                    y: '-10',
                    autoAlpha: 0,
                },
                {
                    y: '0',
                    autoAlpha: 1,
                    stagger: 0.2,
                }
            )
            .fromTo(
                DevicesItems,
                {
                    y: '-10',
                    autoAlpha: 0,
                },
                {
                    y: '0',
                    autoAlpha: 1,
                    stagger: 0.2,
                },
                '<-=1'
            );

        DevicesTimeline.pause();

        const DevicesScene = new ScrollMagic.Scene({
            triggerElement: '.section--devices',
            duration: '60%',
            offset: -50,
        })
        .on('enter', function(e){
            DevicesTimeline.play();
        })
        .addTo(Controller);
    }
}

export default Devices;