class Devicecard {
  constructor() {
    this.init();
  }

  init() {
    const els = document.querySelectorAll(".device-card");
    const showmore = document.querySelectorAll(".js-showmore");
    const loader = document.querySelector(".ls-loader");

    //card-overlay-function
    [].forEach.call(els, function(el, i, els) {
      el.addEventListener("click", function() {
        [].forEach.call(
          els,
          function(el) {
            if (el !== this) {
              this.classList.add("active");
              el.classList.remove("active");
            }
          },
          this
        );
      });
    });
    window.addEventListener("ready", function() {
      if (loader.contains(".ls-loaded")) {
        alert("loaded");
        document.querySelector(".fab-navigator__img").classList.add("active");
      }
    });

    //readmore-function
    for (let i = 0; i < showmore.length; i++) {
      showmore[i].addEventListener("click", function() {
        console.log(this.innerText);
        this.parentNode.previousElementSibling.classList.toggle("show");
        this.classList.toggle("active");
        if (this.classList.contains("active")) {
          this.innerHTML = "Show Less";
        } else {
          this.innerHTML = "Show More";
        }
      });
    }
  }
}

export default Devicecard;
