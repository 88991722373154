/*eslint-disable*/
import gsap from "gsap";

class SupportDevice {
  constructor(parent, story) {
    this.parent = parent;
    this.story = story;
    this.offset = 295;

    const windowWidth = window.innerWidth;
    if (document.querySelector(`${this.story}`)) {
      if (windowWidth >= 300 && windowWidth <= 992) {
        if (window.innerHeight >= 300) {
          this.init();
        }
      } else {
        if (windowWidth >= 300) {
          this.init();
        }
      }
    }
  }

  init() {
    //Initialize the Story Animations
    const ChatController = new ScrollMagic.Controller();

    //Story 1
    const chatMessages1 = document.querySelectorAll(
      `${this.story} .device-set-1`
    );
    const chatMessages2 = document.querySelectorAll(
      `${this.story} .device-set-2`
    );
    const chatMessages3 = document.querySelectorAll(
      `${this.story} .device-set-3`
    );

    const learnMore = document.querySelectorAll(`${this.parent} .learn-btn`);

    //simple stagger
    const ChatTimeline = new gsap.timeline({
      defaults: { ease: "power1.out" }
    });

    ChatTimeline.fromTo(
      chatMessages1,
      {
        // x: "-20",
        // width: 0
        scale: 0,
        autoAlpha: 0
      },
      {
        // x: "0",
        // width: "355px",
        scale: 1,
        autoAlpha: 1,
        duration: 0.4,
        stagger: 0.1 //space each element's animation out by 0.5 seconds
      }
    )
      .fromTo(
        chatMessages2,
        {
          scale: 0,
          autoAlpha: 0
        },
        {
          scale: 1,
          autoAlpha: 1,
          duration: 0.4,
          stagger: 0.1 //space each element's animation out by 0.5 seconds
        },
        "<-=1"
      )
      .fromTo(
        chatMessages3,
        {
          scale: 0,
          autoAlpha: 0
        },
        {
          scale: 1,
          autoAlpha: 1,
          duration: 0.4,
          stagger: 0.1 //space each element's animation out by 0.5 seconds
        },
        "<-=1"
      )
      .fromTo(
        learnMore,
        {
          autoAlpha: 0
        },
        {
          autoAlpha: 1,
          duration: 0.3
        }
      );

    this.setOffset();
    ChatTimeline.pause();

    const self = this;

    window.addEventListener("DOMContentLoaded", self.setOffset, true);
    window.addEventListener("resize", self.setOffset, true);

    let Story1Scene = new ScrollMagic.Scene({
      triggerElement: `${this.parent}`,
      duration: "50%",
      pushFollowers: false,
      offset: this.offset
    })
      .on("start", function() {
        ChatTimeline.play();
        // console.log(triggerElement);
      })
      // .setPin(`${this.parent} .story-wrapper`)
      // .setTween(ChatTimeline)
      .addTo(ChatController);
  }

  setOffset() {
    let windowWidth = window.innerWidth;

    if (windowWidth >= 1200) {
      this.offset = 335;
    } else if (windowWidth >= 300 && windowWidth < 410) {
      this.offset = 150;
    } else if (windowWidth >= 410 && windowWidth < 525) {
      this.offset = 150;
    } else if (windowWidth >= 525 && windowWidth < 767) {
      this.offset = 400;
    } else if (windowWidth >= 767 && windowWidth <= 1200) {
      this.offset = 400;
    }
  }
}

export default SupportDevice;
