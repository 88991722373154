/*eslint-disable*/
import gsap from "gsap";

class CheckStory {
  constructor() {
    this.offset = 295;

    const windowWidth = window.innerWidth;
    if(document.querySelector('.js-story5')){
      if (windowWidth >= 320 && windowWidth <= 992) {
        if (window.innerHeight >= 320) {
          this.init();
        }
      } else {
        if (windowWidth >= 320) {
          this.init();
        }
      }
    }
  }

  init() {
    //Initialize the Story Animations
    const CheckController = new ScrollMagic.Controller();

    //Story 1

    const CheckTimeline1 = new gsap.timeline({
      defaults: { ease: "power1.out", duration: 0.2 }
    });
    const CheckTimeline2 = new gsap.timeline({
      defaults: { ease: "power1.out", duration: 0.2 }
    });

    const storyPart1 = document.querySelector(".js-story5 .js-part1");
    const storyPart2 = document.querySelector(".js-story5 .js-part2");

    const part1Mobile = storyPart1.querySelector(".js-part1-mobile");
    const part2Mobile = storyPart2.querySelector(".js-part2-mobile");

    CheckTimeline1.fromTo(
      part1Mobile,
      0.2,
      {
        y: "50",
        autoAlpha: 0
      },
      {
        y: "-10",
        autoAlpha: 1
      }
    );

    CheckTimeline2.fromTo(
      part2Mobile,
      0.2,
      {
        y: "50",
        autoAlpha: 0
      },
      {
        y: "-10",
        autoAlpha: 1
      }
    );

    this.setOffset();
    CheckTimeline1.pause();
    CheckTimeline2.pause();

    const self = this;

    window.addEventListener("DOMContentLoaded", self.setOffset, true);
    window.addEventListener("resize", self.setOffset, true);

    let StoryScene1 = new ScrollMagic.Scene({
      triggerElement: ".js-story5 .js-part1",
      duration: "50%",
      pushFollowers: false,
      offset: this.offset
    })
      .on("start", function() {
        CheckTimeline1.play();
      })
      .addTo(CheckController);

    let StoryScene2 = new ScrollMagic.Scene({
      triggerElement: ".js-story5 .js-part2",
      duration: "50%",
      pushFollowers: false,
      offset: this.offset
    })
      .on("start", function() {
        CheckTimeline2.play();
      })
      .addTo(CheckController);
  }

  setOffset() {
    let windowWidth = window.innerWidth;

    if (windowWidth >= 1200) {
      this.offset = 320;
    } else if (windowWidth >= 370 && windowWidth < 410) {
      this.offset = 280;
    } else if (windowWidth >= 410 && windowWidth < 767) {
      this.offset = 280;
    } else if (windowWidth >= 767 && windowWidth < 1024) {
      this.offset = 350;
    } else if (windowWidth >= 1024 && windowWidth < 1200) {
      this.offset = 280;
    }
  }
}

export default CheckStory;
