import Swiper from 'swiper';

class DisplaySwiper {
    constructor() {
        if(document.querySelector(".js-display-swiper")) {
            console.log("entered");
            this.init();
        }
    }

    init() {
        const self = this;
        window.addEventListener("load", self.initdisplaySwiper, false);
        window.addEventListener("resize", self.initdisplaySwiper, false);

        const displayPagination = document.querySelectorAll(".image-display-pagination li");

        displayPagination.forEach( function(item,index) {
            item.addEventListener("click",function(){
                document.querySelectorAll(".image-display-pagination li").forEach(function(list) {
                    list.classList.remove("slide-active");
                })
                this.classList.add("slide-active");
                document.querySelector(".js-display-swiper").swiper.slideTo(index);
            })
        });
    }

    initdisplaySwiper() {
          const displaySwiper = new Swiper(".js-display-swiper", {
            slidesPerView: 1,
            pagination: {
                el: '.swiper-pagination',
            },
            breakpoints: {
                1024: {
                    pagination: false, 
                    onlyExternal:true,
                    effect: "fade",
                    fadeEffect: {
                        crossFade: true
                    },
                },
              }
          });

        //   displaySwiper.slideTo(2);
    }
}

export default DisplaySwiper;