import gsap from 'gsap';

class Connect {
    constructor(){
        if(document.querySelector('.section--connects')){
            this.init();
        }
    }

    init(){
    //Connect Section
    const Controller = new ScrollMagic.Controller();

    const ConnectTimeline = new gsap.timeline({defaults:{ease:"power1.out", duration:1}});

    const ConnectHeading = document.querySelector('.section--connects .section-heading');
    const ConnectProducts = document.querySelectorAll('.connects-list li');

    ConnectTimeline
        .fromTo(
            ConnectHeading, 0.4,
            {
                autoAlpha: 0,
            },
            {
                autoAlpha: 1,
            }
        )
        .fromTo(
            ConnectProducts, 0.2,
            {
                autoAlpha: 0,
                y: "100"
            },
            {
                autoAlpha: 1,
                y: "0",
                stagger: 0.4,
            }
        );

    ConnectTimeline.pause();

    const ConnectScene = new ScrollMagic.Scene({
        triggerElement: '.section--connects',
        duration: '60%',
    })
    .on('enter', function(e){
        ConnectTimeline.play();
    })
    .addTo(Controller);
    }
}

export default Connect;