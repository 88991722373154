class ResponsiveImages {
  constructor(image) {
    this.image = document.querySelector(image);
    if (this.image) {
      this.init();
    }
  }

  init() {
    const self = this;

    window.addEventListener(
      "DOMContentLoaded",
      function() {
        self.addListener();
      },
      false
    );

    window.addEventListener(
      "resize",
      function() {
        self.addListener();
      },
      false
    );
  }

  addListener() {
    const windowWidth = window.innerWidth;

    this.image.style.opacity = 0;

    if (windowWidth >= 320 && windowWidth < 360) {
      if (this.image.dataset.img320 !== undefined) {
        this.image.src = this.image.dataset.img320;
      }
    } else if (windowWidth >= 360 && windowWidth < 600) {
      if (this.image.dataset.img360 !== undefined) {
        this.image.src = this.image.dataset.img360;
      }
    } else if (windowWidth >= 600 && windowWidth < 767) {
      if (this.image.dataset.img600 !== undefined) {
        this.image.src = this.image.dataset.img600;
      }
    } else if (windowWidth >= 767 && windowWidth < 1024) {
      if (this.image.dataset.img767 !== undefined) {
        this.image.src = this.image.dataset.img767;
      }
    } else if (windowWidth >= 1024 && windowWidth < 1400) {
      if (this.image.dataset.img1200 !== undefined) {
        this.image.src = this.image.dataset.img1200;
      }
    } else if (windowWidth >= 1400) {
      if (this.image.dataset.img1440 !== undefined) {
        this.image.src = this.image.dataset.img1440;
      }
    }

    this.image.style.opacity = 1;
  }
}

export default ResponsiveImages;
