/*eslint-disable*/
import gsap from "gsap";

class BusinessStory {
  constructor() {
    this.offset = 295;

    const windowWidth = window.innerWidth;

    if (document.querySelector(".js-story-business")) {
      if (windowWidth >= 300 && windowWidth <= 992) {
        if (window.innerHeight >= 300) {
          this.init();
        }
      } else {
        if (windowWidth >= 300) {
          this.init();
        }
      }
    }
  }

  init() {
    //Initialize the Story Animations
    let AdaptController = null;
    AdaptController = new ScrollMagic.Controller();

    //Story 1
    const AdaptPart1Timeline = new gsap.timeline({
      defaults: { ease: "power1.out", duration: 1 }
    });
    const AdaptPart2Timeline = new gsap.timeline({
      defaults: { ease: "power1.out", duration: 1 }
    });
    const AdaptPart3Timeline = new gsap.timeline({
      defaults: { ease: "power1.out", duration: 1 }
    });
    const AdaptPart4Timeline = new gsap.timeline({
      defaults: { ease: "power1.out" }
    });

    const storyPart1 = document.querySelector(".js-story-business .js-part1");
    const storyPart2 = document.querySelector(".js-story-business .js-part2");
    const storyPart3 = document.querySelector(".js-story-business .js-part3");
    const storyPart4 = document.querySelector(".js-story-business .js-part4");

    const overlay = document.querySelectorAll(".js-part1 .black-gradient");

    const part1Mobile = storyPart1.querySelector(".js-part1-mobile");
    const part2Mobile = storyPart2.querySelector(".js-part2-mobile");
    const part3Mobile = storyPart3.querySelector(".js-part3-mobile");
    const part4Mobile = storyPart4.querySelector(".js-part4-mobile");

    AdaptPart1Timeline.fromTo(
      overlay,
      0.2,
      {
        autoAlpha: 0
      },
      {
        autoAlpha: 0.5
      }
    ).fromTo(
      part1Mobile,
      0.2,
      {
        y: "50",
        autoAlpha: 0
      },
      {
        y: "-10",
        autoAlpha: 1
      }
    );

    AdaptPart2Timeline.fromTo(
      overlay,
      0.2,
      {
        autoAlpha: 0
      },
      {
        autoAlpha: 0.5
      }
    ).fromTo(
      part2Mobile,
      0.2,
      {
        y: "50",
        autoAlpha: 0
      },
      {
        y: "-10",
        autoAlpha: 1
      }
    );

    AdaptPart3Timeline.fromTo(
      overlay,
      0.2,
      {
        autoAlpha: 0
      },
      {
        autoAlpha: 0.5
      }
    ).fromTo(
      part3Mobile,
      0.2,
      {
        y: "50",
        autoAlpha: 0
      },
      {
        y: "-10",
        autoAlpha: 1
      }
    );

    AdaptPart4Timeline.fromTo(
      overlay,
      0.2,
      {
        autoAlpha: 0
      },
      {
        autoAlpha: 0.5
      }
    ).fromTo(
      part4Mobile,
      0.2,
      {
        y: "50",
        autoAlpha: 0
      },
      {
        y: "-10",
        autoAlpha: 1
      }
    );

    this.setOffset();

    AdaptPart1Timeline.pause();
    AdaptPart2Timeline.pause();
    AdaptPart3Timeline.pause();
    AdaptPart4Timeline.pause();

    const self = this;

    let Story1Scene = new ScrollMagic.Scene({
      triggerElement: ".js-story-business .js-part1",
      duration: "80%",
      pushFollowers: true,
      offset: this.offset
    })
      .on("start", function() {
        AdaptPart1Timeline.play();
      })
      .addTo(AdaptController);

    let Story2Scene = new ScrollMagic.Scene({
      triggerElement: ".js-story-business .js-part2",
      duration: "80%",
      pushFollowers: true,
      offset: this.offset
    })
      .on("start", function() {
        AdaptPart2Timeline.play();
      })
      .addTo(AdaptController);

    let Story3Scene = new ScrollMagic.Scene({
      triggerElement: ".js-story-business .js-part3",
      duration: "80%",
      pushFollowers: true,
      offset: this.offset
    })
      .on("start", function() {
        AdaptPart3Timeline.play();
      })
      .addTo(AdaptController);

    let Story4Scene = new ScrollMagic.Scene({
      triggerElement: ".js-story-business .js-part4",
      duration: "80%",
      pushFollowers: true,
      offset: this.offset
    })
      .on("start", function() {
        AdaptPart4Timeline.play();
      })
      .addTo(AdaptController);

    window.addEventListener("DOMContentLoaded", self.setOffset, true);
    window.addEventListener("resize", self.setOffset, true);
  }

  setOffset() {
    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;

    if (windowWidth >= 1200) {
      this.offset = 320;
    } else if (windowWidth >= 370 && windowWidth < 767) {
      this.offset = 400;
    } else if (windowWidth >= 410 && windowWidth < 767) {
      this.offset = 250;
    } else if (windowWidth >= 767 && windowWidth < 1024) {
      this.offset = 350;
    } else if (windowWidth >= 1024 && windowWidth < 1200) {
      this.offset = 880;
    }
  }
}

export default BusinessStory;
