import gsap from 'gsap';

class Mini {
    constructor(){
        if(document.querySelector('.mini-content-w')){
            this.init();
        }
    }

    init(){
        //Mini Section
        const Controller = new ScrollMagic.Controller();

        const MiniTimeline = new gsap.timeline({defaults:{ease:"power1.out", duration: 0.3}});

        const MiniContent = document.querySelector('.section--mini .section-heading');
        const MiniDetails = document.querySelectorAll('.section--mini .mini-details-list li');

        MiniTimeline
            .fromTo(
                MiniContent, 0.4,
                {
                    y: '-50',
                    autoAlpha: 0,
                },
                {
                    y: '0',
                    autoAlpha: 1,
                }
            )
            .fromTo(
                MiniDetails, 0.4,
                {
                    y: '-10',
                    autoAlpha: 0,
                },
                {
                    y: '0',
                    autoAlpha: 1,
                    stagger: 0.2,
                }
            );

        MiniTimeline.pause();

        const MiniScene = new ScrollMagic.Scene({
            triggerElement: '.section--mini',
            duration: '60%',
            offset: -50,
        })
        .on('enter', function(e){
            MiniTimeline.play();
        })
        .addTo(Controller);
    }
}

export default Mini;